var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _vm.$root.$data.me.deliveryConsent &&
          _vm.$root.$data.me.deliveryConsent.length > 0
            ? _c(
                "b-col",
                { staticClass: "mt-4", attrs: { cols: "12" } },
                [
                  _c(
                    "b-card",
                    { attrs: { header: "Delivery Consent" } },
                    [_c("delivery-consent-list")],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [_c("page-snippet", { attrs: { name: "tasks" } })],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mt-4", attrs: { cols: "12" } },
            [
              _c(
                "b-card",
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _vm._v(" Tasks ")
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.tasks == null ||
                            _vm.tasks == undefined ||
                            _vm.tasks.length == 0,
                          expression:
                            "tasks == null || tasks == undefined || tasks.length == 0"
                        }
                      ]
                    },
                    [_vm._v(" No tasks found ")]
                  ),
                  _c(
                    "b-table-simple",
                    { attrs: { striped: "", small: "" } },
                    [
                      _c(
                        "b-tbody",
                        _vm._l(_vm.tasks, function(task) {
                          return _c(
                            "b-tr",
                            { key: task.id },
                            [
                              _c("b-td", [
                                _vm._v(" " + _vm._s(task.TaskReference) + " ")
                              ]),
                              _c(
                                "b-td",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "task-detail-overview",
                                          params: { id: task.TaskAttributeID }
                                        }
                                      }
                                    },
                                    [
                                      task.ParentTaskAttributeID != null
                                        ? [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  task.ParentTaskAttribute
                                                    .Description
                                                ) +
                                                " - "
                                            )
                                          ]
                                        : _vm._e(),
                                      _vm._v(
                                        " " + _vm._s(task.Description) + " "
                                      )
                                    ],
                                    2
                                  ),
                                  _c("br"),
                                  task.AlbumID
                                    ? _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "release-detail",
                                              params: { id: task.AlbumID }
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(task.Attribution) +
                                              " - " +
                                              _vm._s(task.Title) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  task.ProgressDescription
                                    ? _c("em", { staticClass: "mb-0" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(task.ProgressDescription) +
                                            " "
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "b-td",
                                [
                                  _c("br"),
                                  task.TicketID
                                    ? [_vm._v(_vm._s(task.TicketID))]
                                    : _vm._e()
                                ],
                                2
                              ),
                              _c(
                                "b-td",
                                { staticStyle: { "white-space": "nowrap" } },
                                [
                                  task.Status
                                    ? [_vm._v(_vm._s(task.Status)), _c("br")]
                                    : _vm._e(),
                                  _vm._v(
                                    " Due " +
                                      _vm._s(task.CompletionDateTimeFormatted) +
                                      " "
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }