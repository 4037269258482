<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12" class="mt-4" v-if="$root.$data.me.deliveryConsent && $root.$data.me.deliveryConsent.length > 0">
        <b-card header="Delivery Consent">
          <delivery-consent-list />
        </b-card>
      </b-col>

      <b-col cols="12">
        <page-snippet name="tasks" />
      </b-col>

      <b-col cols="12" class="mt-4">
        <b-card>
          <div slot="header">
            Tasks
          </div>

          <div v-show="tasks == null || tasks == undefined || tasks.length == 0">
            No tasks found
          </div>

          <b-table-simple striped small>
            <b-tbody>
              <b-tr v-for="task in tasks" v-bind:key="task.id">
                <b-td>
                  {{ task.TaskReference }}
                </b-td>
                <b-td>
                  <router-link 
                    :to="{ 
                      name: 'task-detail-overview', 
                      params: { id: task.TaskAttributeID } 
                    }"
                  >
                    <template v-if="task.ParentTaskAttributeID != null">
                      {{ task.ParentTaskAttribute.Description }} - 
                    </template>
                    {{ task.Description }}
                  </router-link>
                  <br>
                  <router-link v-if="task.AlbumID" :to="{ name: 'release-detail', params: { id: task.AlbumID } }">
                    {{ task.Attribution }} - {{ task.Title }}
                  </router-link>
                  <em class="mb-0" v-if="task.ProgressDescription">
                    {{ task.ProgressDescription }}
                  </em>
                </b-td>
                <b-td>
                  <br>
                  <template v-if="task.TicketID">{{ task.TicketID }}</template>
                </b-td>
                <b-td style="white-space: nowrap;">
                  <template v-if="task.Status">{{ task.Status }}<br></template>
                  Due {{ task.CompletionDateTimeFormatted }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    components: {
      'page-snippet': () => import('@/components/PageSnippet'),
      'delivery-consent-list': () => import('@/components/DeliveryConsentList'),
    },
    computed: {
      tasks() {
        if (!this.$root.$data.me.tasks) return []
        return this.$root.$data.me.tasks
      }
    },
    props: {
    },
    mounted () {
      this.$root.loadTasks()
    }
  }
</script>